<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="7" class="py-0">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="name_field"
                            :label="$t('forms.LevelForm.name.label')"
                            :hint="$t('forms.LevelForm.name.hint')"
                            :placeholder="$t('forms.LevelForm.name.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('name')"
                            v-model="value.name"
                            @input="clearErrorMessages('name')"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="trigger_amount_field"
                            :label="$t('forms.LevelForm.trigger_amount.label')"
                            :hint="isFirstLevel || isLevelCreated ? $t('forms.LevelForm.trigger_amount.hint_readonly') : $t('forms.LevelForm.trigger_amount.hint')"
                            :placeholder="$t('forms.LevelForm.trigger_amount.placeholder')"
                            required type="number"
                            :error-messages="getErrorMessages('trigger_amount')"
                            v-model="value.trigger_amount"
                            :readonly="isFirstLevel || isLevelCreated"
                            @input="clearErrorMessages('trigger_amount')"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-textarea
                            ref="description_field"
                            :label="$t('forms.LevelForm.description.label')"
                            :hint="$t('forms.LevelForm.description.hint')"
                            :placeholder="$t('forms.LevelForm.description.placeholder')"
                            required type="text"
                            :error-messages="getErrorMessages('description')"
                            v-model="value.description"
                            rows="4"
                            @input="clearErrorMessages('description')"
                            @keyup.enter="$emit('triggerSubmit')"
                        />
                    </v-col>
                </v-row>
            </v-col>            

            <v-col cols="12" xs="12" sm="12" md="5" class="py-4">                
                <ColorPickerInput
                    ref="color_field"
                    mode="hexa"
                    width="200px"
                    :label="$t('forms.LevelForm.color.label')"
                    v-model="value.color"
                    :error-messages="getErrorMessages('color')"
                    @input="colorChanged"
                    class="mx-auto elevation-0"
                />
            </v-col>

            <v-col v-if="formType == 'EDIT' && isLevelCreated" cols="12">
                <v-row>
                    <v-col v-can:list="'Benefit'" cols="12" xs="12" sm="12" md="6" class="py-0 pr-md-1">
                        <v-skeleton-loader
                            type="card"
                            v-if="!value"
                        ></v-skeleton-loader>
                        <AccessCard v-else :to="{ name: 'levels.benefits', params: { levelId: value.id } }" :title="$t('views.levels.Edit.benefits')">
                            <template #icon>
                                <v-icon :size="25" color="primary">$cp_plus_square</v-icon>
                            </template>
                        </AccessCard>
                    </v-col>

                    <v-col v-can:list="'Prize'" cols="12" xs="12" sm="12" md="6" class="mt-2 mt-md-0 py-0 pl-md-1">
                        <v-skeleton-loader
                            type="card"
                            v-if="!value"
                        ></v-skeleton-loader>
                        <AccessCard v-else :to="{ name: 'levels.prize', params: { levelId: value.id } }" :title="$t('views.levels.Details.prize')">
                            <template #icon>
                                <v-icon :size="25" color="primary">$cp_shopping_bag</v-icon>
                            </template>
                        </AccessCard>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="formType == 'EDIT' && isLevelCreated" cols="12" class="pt-8 pb-4">
                <div class="d-flex flex-column flex-md-row align-md-center">
                    <label for="tos_url_field" class="mr-0 mr-md-4 primary--text">{{ $t('forms.LevelForm.tos_url.label') }}</label>
                    <FileInput 
                        ref="tos_url_field"
                        :value="value.tos_url"
                        @input="onInput('tos_url', $event)"
                        type="update-level-tos"
                        :params="{ level_id: value.id }"
                        :maxSizeMB="4"
                        class="mt-2 mt-md-0"
                    />
                </div>
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import ColorPickerInput from '@/components/inputs/ColorPickerInput'
import AccessCard       from '@/components/cards/AccessCard'
import FileInput        from '@/components/inputs/FileInput'
import FormMixin        from '@/mixins/FormMixin'
import Level            from '@/models/Level'
import { mapGetters }   from 'vuex'

export default {
    name: 'LevelForm',
    mixins: [ FormMixin(Level, 'name_field') ],
    props: {
        formType    : String,
        isFirstLevel: {
            type: Boolean,
            default: false,
        },
    },
    components: { FileInput, AccessCard, ColorPickerInput },
    created() {
        // Garante que o primeiro nível sempre tenha trigger_amount == 0
        if (this.isFirstLevel)
            this.value.trigger_amount = 0
    },
    methods: {
        colorChanged(value) {
            this.value.color = typeof(value) == 'string' ? value.substring(0, 7) : value.hex.substring(0, 7)
        },
        updateLevel(attributeName) {
            return async (value) => {
                this.$emit('cloneLevel')
            }
        },
        onInput(attributeName, value) {
            this.$set(this.value, attributeName, value)
        },
    },
    computed: {
        ...mapGetters({
            levels        : 'level/levels',
            isLevelCreated: 'level/isCreated', 
        }),
    },
}
</script>