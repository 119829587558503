var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: {
        to: _vm.to || "",
        height: _vm.hasSlot("icon") ? "120px" : "65px",
        outlined: ""
      }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "secondary_font--text action-card-title" },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm.hasSlot("icon")
        ? _c(
            "v-card-text",
            { staticClass: "customer-details-icon text-center" },
            [_vm._t("icon")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }