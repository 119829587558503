<template>
    <v-card :to="to || ''" :height="hasSlot('icon') ? '120px' : '65px'" outlined>
        <v-card-title class="secondary_font--text action-card-title">{{ title }}</v-card-title>
        <v-card-text v-if="hasSlot('icon')" class="customer-details-icon text-center">
            <slot name="icon"></slot>
        </v-card-text>
    </v-card>
</template>

<script>
import HasSlot from '@/mixins/HasSlot'

export default {
    name: 'AccessCard',
    mixins: [ HasSlot ],
    props: {
        title: {
            type: String,
            required: true,
        },
        to: {
            type: Object,
            required: false,
        },
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.action-card-title {
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>