<template>
    <v-container fluid class="py-0">
        <CreateModal ref="create_modal" :level="selectedLevel" :isFirstLevel="!this.levels.length" />
        <DetailsModal ref="details_modal" :level="selectedLevel" />
        <EditModal ref="edit_modal" :level="selectedLevel" :isFirstLevel="selectedLevel.id == '0'" /> 
        <ConfirmationModal ref="submit_confirmation_modal" />

        <span class="pl-5 pl-md-12 section-title">{{ $t('views.levels.List.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <LevelsTable 
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10" 
            :fetchFunction="fetchFunction"
            @submit-levels="openSubmitConfirmation" 
            @create="openCreate" 
            @details="openDetails" 
            @edit="openEdit"
            @delete="handleDelete"
        />
    </v-container>
</template>

<script>
import DetailsModal         from '@/components/modals/levels/DetailsModal'
import CreateModal          from '@/components/modals/levels/CreateModal'
import ConfirmationModal    from '@/components/modals/ConfirmationModal'
import EditModal            from '@/components/modals/levels/EditModal'
import LevelsTable          from '@/components/tables/LevelsTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Level                from '@/models/Level'
import { mapGetters }       from 'vuex'

export default {
    props: {
        newLevel: {
            type   : Level,
            default: null,
        },
    },
    data: () => ({
        selectedLevel: new Level,
        hasError     : false,
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { LevelsTable, CreateModal, EditModal, DetailsModal, ConfirmationModal },
    methods: {
        async submitLevels() {
            this.loading = true

            let errType
            let response = await Level.createLevelsSet(this.levels)
                .catch(err => {
                    this.loading     = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error_create'
                })

                if (errType) {
                    const definedErrors = [
                        'non_zero_first_trigger_amount',
                        'non_ascending_trigger_amounts',
                    ]
                    errType = definedErrors.includes(errType) ? errType : 'error'
                    await new Promise((resolve) => {
                        this.$bus.$emit('alert', this.$t(`views.levels.List.alerts.${errType}`), 'error', resolve)
                    })
                    return
                }

            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t(`views.levels.List.alerts.finish_success`), 'success', resolve)
            })

            await this.refreshTable()

            this.loading = false
        },
        async openSubmitConfirmation() {
            let r = await this.$refs.submit_confirmation_modal.showConfirmation(this.$t('views.levels.List.confirmations.confirm_submit'))
            if (r)
                await this.submitLevels()
        },
        async openCreate() {
            this.selectedLevel = new Level()
            let r = await this.$refs.create_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.levels.List.alerts.create_success'), 'success')
            await this.refreshTable()
        },
        async openDetails(level) {
            this.selectedLevel = level
            this.$refs.details_modal.open()
        },
        async openEdit(level) {
            // Precisa criar uma nova instância
            this.selectedLevel = new Level(this.$lodash.cloneDeep(level))
            let r = await this.$refs.edit_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.levels.List.alerts.edit_success'), 'success')
            await this.refreshTable()
        },
        async handleDelete(level) {
            // Não permite deletar o primeiro nível
            if (level.id == 0) {
                this.$refs.table.showDeleteError('delete_first_level')
                return
            }

            this.$store.dispatch('level/deleteLevel', level.id)

            this.$bus.$emit('message', this.$t('views.levels.List.alerts.delete_success'), 'success')
            await this.refreshTable() 
        },
        async refreshTable() {
            await this.$refs.table.refresh()
        },
        async fetchFunction(pagination) {
            this.hasError = false
            await this.$store.dispatch('level/fetchLevels')
                .catch(this.preErrorHandler)
                
            let firstIndex          = pagination.per_page * (pagination.page - 1)
            let lastExclusiveIndex  = firstIndex + pagination.per_page
            return this.levels.slice(firstIndex, lastExclusiveIndex)
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
        handleDeleted() {
            this.refreshTable()    
        },
    },
    computed: {
        ...mapGetters({
            levels: 'level/levels',
        }),
    }
}
</script>