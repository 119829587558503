var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "7" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "name_field",
                        attrs: {
                          label: _vm.$t("forms.LevelForm.name.label"),
                          hint: _vm.$t("forms.LevelForm.name.hint"),
                          placeholder: _vm.$t(
                            "forms.LevelForm.name.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("name")
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("name")
                          }
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "trigger_amount_field",
                        attrs: {
                          label: _vm.$t("forms.LevelForm.trigger_amount.label"),
                          hint:
                            _vm.isFirstLevel || _vm.isLevelCreated
                              ? _vm.$t(
                                  "forms.LevelForm.trigger_amount.hint_readonly"
                                )
                              : _vm.$t("forms.LevelForm.trigger_amount.hint"),
                          placeholder: _vm.$t(
                            "forms.LevelForm.trigger_amount.placeholder"
                          ),
                          required: "",
                          type: "number",
                          "error-messages": _vm.getErrorMessages(
                            "trigger_amount"
                          ),
                          readonly: _vm.isFirstLevel || _vm.isLevelCreated
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("trigger_amount")
                          }
                        },
                        model: {
                          value: _vm.value.trigger_amount,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "trigger_amount", $$v)
                          },
                          expression: "value.trigger_amount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-textarea", {
                        ref: "description_field",
                        attrs: {
                          label: _vm.$t("forms.LevelForm.description.label"),
                          hint: _vm.$t("forms.LevelForm.description.hint"),
                          placeholder: _vm.$t(
                            "forms.LevelForm.description.placeholder"
                          ),
                          required: "",
                          type: "text",
                          "error-messages": _vm.getErrorMessages("description"),
                          rows: "4"
                        },
                        on: {
                          input: function($event) {
                            return _vm.clearErrorMessages("description")
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.$emit("triggerSubmit")
                          }
                        },
                        model: {
                          value: _vm.value.description,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "description", $$v)
                          },
                          expression: "value.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-4",
              attrs: { cols: "12", xs: "12", sm: "12", md: "5" }
            },
            [
              _c("ColorPickerInput", {
                ref: "color_field",
                staticClass: "mx-auto elevation-0",
                attrs: {
                  mode: "hexa",
                  width: "200px",
                  label: _vm.$t("forms.LevelForm.color.label"),
                  "error-messages": _vm.getErrorMessages("color")
                },
                on: { input: _vm.colorChanged },
                model: {
                  value: _vm.value.color,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "color", $$v)
                  },
                  expression: "value.color"
                }
              })
            ],
            1
          ),
          _vm.formType == "EDIT" && _vm.isLevelCreated
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:list",
                              value: "Benefit",
                              expression: "'Benefit'",
                              arg: "list"
                            }
                          ],
                          staticClass: "py-0 pr-md-1",
                          attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                        },
                        [
                          !_vm.value
                            ? _c("v-skeleton-loader", {
                                attrs: { type: "card" }
                              })
                            : _c("AccessCard", {
                                attrs: {
                                  to: {
                                    name: "levels.benefits",
                                    params: { levelId: _vm.value.id }
                                  },
                                  title: _vm.$t("views.levels.Edit.benefits")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: 25,
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("$cp_plus_square")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2951057940
                                )
                              })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:list",
                              value: "Prize",
                              expression: "'Prize'",
                              arg: "list"
                            }
                          ],
                          staticClass: "mt-2 mt-md-0 py-0 pl-md-1",
                          attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                        },
                        [
                          !_vm.value
                            ? _c("v-skeleton-loader", {
                                attrs: { type: "card" }
                              })
                            : _c("AccessCard", {
                                attrs: {
                                  to: {
                                    name: "levels.prize",
                                    params: { levelId: _vm.value.id }
                                  },
                                  title: _vm.$t("views.levels.Details.prize")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: 25,
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("$cp_shopping_bag")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1125393023
                                )
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.formType == "EDIT" && _vm.isLevelCreated
            ? _c("v-col", { staticClass: "pt-8 pb-4", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column flex-md-row align-md-center"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "mr-0 mr-md-4 primary--text",
                        attrs: { for: "tos_url_field" }
                      },
                      [_vm._v(_vm._s(_vm.$t("forms.LevelForm.tos_url.label")))]
                    ),
                    _c("FileInput", {
                      ref: "tos_url_field",
                      staticClass: "mt-2 mt-md-0",
                      attrs: {
                        value: _vm.value.tos_url,
                        type: "update-level-tos",
                        params: { level_id: _vm.value.id },
                        maxSizeMB: 4
                      },
                      on: {
                        input: function($event) {
                          return _vm.onInput("tos_url", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }