<template>
    <div>
        <BaseCrudTable 
            ref="table"
            tableName="LevelsTable" 
            :headerConfigs="headerConfigs"
            :fetchFunction="fetchFunction"
            :pagination="pagination" 
            :createBtnHidden="isLevelCreated" 
            :deleteBtnDisabled="isLevelCreated"
            @create="handleCreate" 
            @details="handleDetails"
            @edit="handleEdit" 
            @delete="handleDelete"
        >
            <template #item.color="{ item }">
                <ColorBox :color="item.color"></ColorBox>
            </template>

            <template #item.trigger_amount="{ item }">
                <div v-if="item.next && item.next.amount">
                    {{ `${roundNumber(item.trigger_amount)} - ${roundNumber(item.next.amount - 0.01)}` }}
                </div>
                <div v-else>
                    {{ `+ ${roundNumber(item.trigger_amount)}` }}
                </div>
            </template>
        </BaseCrudTable>
        <v-btn v-if="!isLevelCreated" :disabled="!levels.length" @click="$emit('submit-levels')" class="mt-0 finish-button" block depressed color="primary">{{ $t('actions.finish') }}</v-btn>
    </div>
</template>

<script>
import BaseCrudTable   from '@/components/tables/BaseCrudTable'
import ColorBox        from '@/components/boxes/ColorBox'
import { roundNumber } from '@/utils/mathUtils'
import Level           from '@/models/Level'
import { mapGetters }  from 'vuex'

export default {
    name: 'LevelsTable',
    props: {
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    components: { BaseCrudTable, ColorBox },
    data: () => ({
        pagination: {
            page    : 1,
            per_page: 10,
        },
        headerConfigs: [
            {
                value: 'color', 
                showOnMobile: true,
            },
            {
                value: 'name', 
                showOnMobile: true,
            },
            {
                value: 'trigger_amount',
                showOnMobile: false,
            },
        ],
    }),
    created() {
        this.pagination.page = Number(this.$route.query.page) || this.pagination.page || 1
    },
    methods: {
        async refresh() {
            await this.$refs.table.preFetchItems()
        },
        handleCreate() {
            this.$emit('create')
        },
        handleDetails(item) {
            this.$emit('details', item)
        },
        handleEdit(item) {
            this.$emit('edit', item)
        },
        handleDelete(item) {
            this.$emit('delete', item)
        },
        roundNumber(...args) {
            return roundNumber(...args)
        },
    },
    computed: {
        ...mapGetters({
            isLevelCreated: 'level/isCreated',
            levels: 'level/levels',
        }),
    },
}
</script>

<style scoped lang="scss">
.finish-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>