<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="confirm"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="570px"
    >
        <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="pt-0 pb-10">
                <ColorPickerInput
                    ref="color_field"
                    class="mx-auto"
                    mode="hexa"
                    width="160px"
                    :label="$t('forms.LevelForm.color.label')"
                    hide-canvas
                    disabled
                    v-model="level.color"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="7" class="py-0">
                <v-row>
                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="name_field"
                            :label="$t('forms.LevelForm.name.label')"
                            readonly type="text"
                            v-model="level.name"
                        />
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <cp-text-field
                            ref="trigger_amount_field"
                            :label="$t('forms.LevelForm.trigger_amount.label')"
                            readonly type="number"
                            v-model="level.trigger_amount"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" xs="12" sm="12" md="5" class="px-0 pt-6">
                <ColorPickerInput
                    ref="color_field"
                    class="mx-auto"
                    mode="hexa"
                    width="200px"
                    :label="$t('forms.LevelForm.color.label')"
                    hide-canvas
                    disabled
                    v-model="level.color"
                />
            </v-col>

            <v-col cols="12" class="py-0">
                <cp-textarea
                    ref="description_field"
                    :label="$t('forms.LevelForm.description.label')"
                    readonly type="text"
                    rows="4"
                    v-model="level.description"
                />
            </v-col>

            <v-col v-if="isLevelCreated" cols="12">
                <v-row>
                    <v-col v-can:list="'Benefit'" cols="12" xs="12" sm="12" md="6" class="py-0 pr-md-1">
                        <v-skeleton-loader
                            type="card"
                            v-if="!level"
                        ></v-skeleton-loader>
                        <AccessCard v-else :to="{ name: 'levels.benefits', params: { levelId: level.id } }" :title="$t('views.levels.Details.benefits')">
                            <template #icon>
                                <v-icon :size="25" color="primary">$cp_plus_square</v-icon>
                            </template>
                        </AccessCard>
                    </v-col>

                    <v-col v-can:list="'Prize'" cols="12" xs="12" sm="12" md="6" class="mt-2 mt-md-0 py-0 pl-md-1">
                        <v-skeleton-loader
                            type="card"
                            v-if="!level"
                        ></v-skeleton-loader>
                        <AccessCard v-else :to="{ name: 'levels.prize', params: { levelId: level.id } }" :title="$t('views.levels.Details.prize')">
                            <template #icon>
                                <v-icon :size="25" color="primary">$cp_shopping_bag</v-icon>
                            </template>
                        </AccessCard>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="isLevelCreated && level.tos_url" cols="12">
                <div class="d-flex flex-column flex-md-row align-md-center">
                    <label for="tos_url_field" class="mr-0 mr-md-4 primary--text">{{ $t('forms.LevelForm.tos_url.label_view') }}</label>
                    <v-chip
                        ref="tos_url_field"
                        @click="redirectToFile"
                        small class="mt-2 mt-md-0 secondary_font--text background"
                    >
                        {{ $t('components.inputs.FileInput.view') }}
                        <v-icon x-small class="ml-6 primary--text">fas fa-fw fa-file-download</v-icon>
                    </v-chip>
                </div>
            </v-col>
        </v-row>
    </BaseModal>
</template>

<script>
import ColorPickerInput from '@/components/inputs/ColorPickerInput'
import AccessCard from '@/components/cards/AccessCard'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Level from '@/models/Level'
import { mapGetters } from 'vuex'

export default {
    name: 'LevelsDetailsModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, AccessCard, ColorPickerInput },
    props: {
        level: {
            type: Level,
            required: true,
        },
    },
    data: vm => {
        return {
            title: vm.$t('views.levels.Details.title'),
        }
    },
    methods: {
        redirectToFile() {
            if (this.level)
                window.open(this.level.tos_url, '_blank')
        },
    },
    computed: {
        ...mapGetters({
            isLevelCreated: 'level/isCreated', 
        }),

        computedConfirmButton() {
            return {
                label: this.$t('actions.close'),
            }
        },
        computedCancelButton() {
            return {
                hide: true,
            }
        },
    },
}
</script>