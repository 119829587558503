var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        "max-width": "570px"
      },
      on: { cancel: _vm.cancel, confirm: _vm.confirm },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                { staticClass: "pt-0 pb-10", attrs: { cols: "12" } },
                [
                  _c("ColorPickerInput", {
                    ref: "color_field",
                    staticClass: "mx-auto",
                    attrs: {
                      mode: "hexa",
                      width: "160px",
                      label: _vm.$t("forms.LevelForm.color.label"),
                      "hide-canvas": "",
                      disabled: ""
                    },
                    model: {
                      value: _vm.level.color,
                      callback: function($$v) {
                        _vm.$set(_vm.level, "color", $$v)
                      },
                      expression: "level.color"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", xs: "12", sm: "12", md: "7" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "name_field",
                        attrs: {
                          label: _vm.$t("forms.LevelForm.name.label"),
                          readonly: "",
                          type: "text"
                        },
                        model: {
                          value: _vm.level.name,
                          callback: function($$v) {
                            _vm.$set(_vm.level, "name", $$v)
                          },
                          expression: "level.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c("cp-text-field", {
                        ref: "trigger_amount_field",
                        attrs: {
                          label: _vm.$t("forms.LevelForm.trigger_amount.label"),
                          readonly: "",
                          type: "number"
                        },
                        model: {
                          value: _vm.level.trigger_amount,
                          callback: function($$v) {
                            _vm.$set(_vm.level, "trigger_amount", $$v)
                          },
                          expression: "level.trigger_amount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "px-0 pt-6",
                  attrs: { cols: "12", xs: "12", sm: "12", md: "5" }
                },
                [
                  _c("ColorPickerInput", {
                    ref: "color_field",
                    staticClass: "mx-auto",
                    attrs: {
                      mode: "hexa",
                      width: "200px",
                      label: _vm.$t("forms.LevelForm.color.label"),
                      "hide-canvas": "",
                      disabled: ""
                    },
                    model: {
                      value: _vm.level.color,
                      callback: function($$v) {
                        _vm.$set(_vm.level, "color", $$v)
                      },
                      expression: "level.color"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-textarea", {
                ref: "description_field",
                attrs: {
                  label: _vm.$t("forms.LevelForm.description.label"),
                  readonly: "",
                  type: "text",
                  rows: "4"
                },
                model: {
                  value: _vm.level.description,
                  callback: function($$v) {
                    _vm.$set(_vm.level, "description", $$v)
                  },
                  expression: "level.description"
                }
              })
            ],
            1
          ),
          _vm.isLevelCreated
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:list",
                              value: "Benefit",
                              expression: "'Benefit'",
                              arg: "list"
                            }
                          ],
                          staticClass: "py-0 pr-md-1",
                          attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                        },
                        [
                          !_vm.level
                            ? _c("v-skeleton-loader", {
                                attrs: { type: "card" }
                              })
                            : _c("AccessCard", {
                                attrs: {
                                  to: {
                                    name: "levels.benefits",
                                    params: { levelId: _vm.level.id }
                                  },
                                  title: _vm.$t("views.levels.Details.benefits")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: 25,
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("$cp_plus_square")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2951057940
                                )
                              })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:list",
                              value: "Prize",
                              expression: "'Prize'",
                              arg: "list"
                            }
                          ],
                          staticClass: "mt-2 mt-md-0 py-0 pl-md-1",
                          attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
                        },
                        [
                          !_vm.level
                            ? _c("v-skeleton-loader", {
                                attrs: { type: "card" }
                              })
                            : _c("AccessCard", {
                                attrs: {
                                  to: {
                                    name: "levels.prize",
                                    params: { levelId: _vm.level.id }
                                  },
                                  title: _vm.$t("views.levels.Details.prize")
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                size: 25,
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("$cp_shopping_bag")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1125393023
                                )
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isLevelCreated && _vm.level.tos_url
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column flex-md-row align-md-center"
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "mr-0 mr-md-4 primary--text",
                        attrs: { for: "tos_url_field" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("forms.LevelForm.tos_url.label_view"))
                        )
                      ]
                    ),
                    _c(
                      "v-chip",
                      {
                        ref: "tos_url_field",
                        staticClass:
                          "mt-2 mt-md-0 secondary_font--text background",
                        attrs: { small: "" },
                        on: { click: _vm.redirectToFile }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("components.inputs.FileInput.view")) +
                            " "
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "ml-6 primary--text",
                            attrs: { "x-small": "" }
                          },
                          [_vm._v("fas fa-fw fa-file-download")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }