var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseCrudTable", {
        ref: "table",
        attrs: {
          tableName: "LevelsTable",
          headerConfigs: _vm.headerConfigs,
          fetchFunction: _vm.fetchFunction,
          pagination: _vm.pagination,
          createBtnHidden: _vm.isLevelCreated,
          deleteBtnDisabled: _vm.isLevelCreated
        },
        on: {
          create: _vm.handleCreate,
          details: _vm.handleDetails,
          edit: _vm.handleEdit,
          delete: _vm.handleDelete
        },
        scopedSlots: _vm._u([
          {
            key: "item.color",
            fn: function(ref) {
              var item = ref.item
              return [_c("ColorBox", { attrs: { color: item.color } })]
            }
          },
          {
            key: "item.trigger_amount",
            fn: function(ref) {
              var item = ref.item
              return [
                item.next && item.next.amount
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.roundNumber(item.trigger_amount) +
                              " - " +
                              _vm.roundNumber(item.next.amount - 0.01)
                          ) +
                          " "
                      )
                    ])
                  : _c("div", [
                      _vm._v(
                        " " +
                          _vm._s("+ " + _vm.roundNumber(item.trigger_amount)) +
                          " "
                      )
                    ])
              ]
            }
          }
        ])
      }),
      !_vm.isLevelCreated
        ? _c(
            "v-btn",
            {
              staticClass: "mt-0 finish-button",
              attrs: {
                disabled: !_vm.levels.length,
                block: "",
                depressed: "",
                color: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("submit-levels")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("actions.finish")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }