var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "" } },
    [
      _c("CreateModal", {
        ref: "create_modal",
        attrs: { level: _vm.selectedLevel, isFirstLevel: !this.levels.length }
      }),
      _c("DetailsModal", {
        ref: "details_modal",
        attrs: { level: _vm.selectedLevel }
      }),
      _c("EditModal", {
        ref: "edit_modal",
        attrs: {
          level: _vm.selectedLevel,
          isFirstLevel: _vm.selectedLevel.id == "0"
        }
      }),
      _c("ConfirmationModal", { ref: "submit_confirmation_modal" }),
      _c("span", { staticClass: "pl-5 pl-md-12 section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.levels.List.title")))
      ]),
      _vm.hasError
        ? _c(
            "v-alert",
            {
              staticClass: "mt-4 mx-5 mt-md-6 mx-md-12",
              attrs: { type: "error" }
            },
            [_vm._v(" " + _vm._s(_vm.$t("errors.generic")) + " ")]
          )
        : _c("LevelsTable", {
            ref: "table",
            staticClass: "my-4 mx-2 mx-md-10",
            attrs: { fetchFunction: _vm.fetchFunction },
            on: {
              "submit-levels": _vm.openSubmitConfirmation,
              create: _vm.openCreate,
              details: _vm.openDetails,
              edit: _vm.openEdit,
              delete: _vm.handleDelete
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }