<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="570px"
        persistent
    >
        <LevelForm
            ref="level_form"
            v-if="!loading"
            v-model="level"
            :isFirstLevel="isFirstLevel"
            :isFormValid.sync="isFormValid"
            :formType="'CREATE'"
            @submit="confirm"
            @triggerSubmit="triggerSubmit"
            :loading="loading"
        />
        <span v-else>{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import LevelForm from '@/components/forms/LevelForm'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Level from '@/models/Level'

export default {
    name: 'LevelsCreateModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, LevelForm },
    props: {
        level: {
            type: Level,
            required: true,
        },
        isFirstLevel: {
            type: Boolean,
            default: false,
        },
    },
    data: vm => {
        return {
            isFormValid: false,
            title: vm.$t('views.levels.Create.title'),
            loading: false,
        }
    },
    methods: {
        triggerSubmit() {
            this.$refs.level_form.submit()
        },
        async beforeConfirm(level) {
            this.$store.dispatch('level/addLevel', level)

            this.resolveValue = this.level
            return true
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>